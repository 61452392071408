:root {
  --light-bg: rgb(247, 249, 249);
  --light-blue: rgb(233, 243, 244);
  --main-box-shadow: 1.5px 1.5px 3px 0 rgba(174, 174, 192, 0.4), -1px -1px 3px 0 rgb(255, 255, 255);
  --inset-box-shadow: 2px 2px 3px 0 rgba(174, 174, 192, 0.4) inset, -2px -2px 3px 0 rgb(255, 255, 255) inset;
  --inset-box-shadow-blue: 2px 2px 3px 0 rgba(95, 162, 231) inset, -2px -2px 3px 0 rgb(177, 216, 255) inset;
  --modal-box-shadow: 1.5px 1.5px 3px 0 rgba(174, 174, 192, 0.4), -3px -3px 8px 0 rgb(255, 255, 255);
  --separator-shadow: 1px 1px 1px 0px #AEAEC066 inset, -1px -1px 1px 0px #FFFFFF inset;
  --main-text: rgba(39, 39, 39);
  --grey-text: rgba(165, 181, 189);
  --grey-text-hover: rgba(127, 143, 152);
  --active-icon: rgba(94, 132, 247);
  --active-icon-hover: rgba(77, 118, 240);
  --icon-blue: rgba(133, 187, 243, 1);
  --primary-blue: #5E84F7;
  --primary-gradient: linear-gradient(92.62deg, #5E84F7 14%, #547BF2 86.27%);
  --primary-disabled: rgb(160, 185, 244);
  --primary-disabled-text: rgb(200, 215, 250);
  --primary-gradient-hover: linear-gradient(92.62deg, #4D76F0 14%, #3F68E4 86.27%);
  --button-shadow: 1.5px 1.5px 3px 0 rgba(174, 174, 192, 0.4), -1px -1px 3px 0px rgb(255, 255, 255);
  --drop-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.21);
  --hard-shadow-on-the-map: 0px 0px 9px 0px rgba(0, 0, 0, 0.35);




  //material overridden var
  --mdc-checkbox-state-layer-size: 18px;
  --mdc-checkbox-selected-icon-color: #5E84F7;
  --mat-option-selected-state-label-text-color: #5E84F7;
  --mdc-checkbox-selected-focus-icon-color: #5E84F7;
  --mdc-checkbox-selected-hover-icon-color: #5E84F7;
  --mdc-checkbox-selected-pressed-icon-color: #5E84F7;
  --mdc-checkbox-selected-focus-state-layer-color: #5E84F7;
  --mdc-checkbox-selected-hover-state-layer-color: #5E84F7;
  --mdc-checkbox-selected-pressed-state-layer-color: #5E84F7;
  --blue: rgba(133, 187, 243, 1);
  --blue-hover: rgba(87, 150, 215, 1);
  --grey: rgba(165, 181, 189, 1);
  --grey-hover: rgba(127, 143, 152, 1);
  --text: rgba(39, 39, 39, 1);
  --lines: rgba(230, 230, 230, 1);
  --white: rgba(255, 255, 255, 1);
  --error: rgba(235, 87, 82, 1);
  --pink: rgba(218, 136, 211, 1);
  --pink-hover: rgba(194, 105, 187, 1);
  --website-h1-font-family: "Montserrat", Helvetica;
  --website-h1-font-weight: 600;
  --website-h1-font-size: 64px;
  --website-h1-letter-spacing: 0px;
  --website-h1-line-height: 148.00000190734863%;
  --website-h1-font-style: normal;
  --website-h2-font-family: "Montserrat", Helvetica;
  --website-h2-font-weight: 600;
  --website-h2-font-size: 48px;
  --website-h2-letter-spacing: 0px;
  --website-h2-line-height: 148.00000190734863%;
  --website-h2-font-style: normal;
  --website-h3-font-family: "Montserrat", Helvetica;
  --website-h3-font-weight: 600;
  --website-h3-font-size: 32px;
  --website-h3-letter-spacing: 0px;
  --website-h3-line-height: 148.00000190734863%;
  --website-h3-font-style: normal;
  --website-h4-font-family: "Montserrat", Helvetica;
  --website-h4-font-weight: 600;
  --website-h4-font-size: 24px;
  --website-h4-letter-spacing: 0px;
  --website-h4-line-height: 148.00000190734863%;
  --website-h4-font-style: normal;
  --website-b1-font-family: "Montserrat", Helvetica;
  --website-b1-font-weight: 500;
  --website-b1-font-size: 24px;
  --website-b1-letter-spacing: 0px;
  --website-b1-line-height: 148.00000190734863%;
  --website-b1-font-style: normal;
  --website-b2-font-family: "Montserrat", Helvetica;
  --website-b2-font-weight: 500;
  --website-b2-font-size: 18px;
  --website-b2-letter-spacing: 0px;
  --website-b2-line-height: 148.00000190734863%;
  --website-b2-font-style: normal;
  --website-b3-font-family: "Montserrat", Helvetica;
  --website-b3-font-weight: 500;
  --website-b3-font-size: 16px;
  --website-b3-letter-spacing: 0px;
  --website-b3-line-height: 148.00000190734863%;
  --website-b3-font-style: normal;
  --1: 1.5px 1.5px 3px 0px rgba(174, 174, 192, 0.4), -1px -1px 3px 0px rgba(255, 255, 255, 1);
  --11111111: 1.5px 1.5px 9px 0px rgba(176, 209, 212, 0.36), -1px -1px 3px 0px rgba(255, 255, 255, 1);
  --shadow-on-white: 1.5px 1.5px 3px 0px rgba(174, 174, 192, 0.4), -1px -1px 3px 0px rgba(255, 255, 255, 1);
  --shadow-on-blue: 1.5px 1.5px 3px 0px rgba(96, 162, 231, 1), -1px -1px 3px 0px rgba(255, 255, 255, .63);
  --shadow-on-map: 0px 4px 10px 0px rgba(0, 0, 0, 0.18);
  --single-shadow: 1.5px 1.5px 3px 0px rgba(174, 174, 192, 0.4);
  --standard-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.11);
  --dark-theme-shadow: 1.5px 1.5px 3px 0px rgba(33, 33, 33, 1), -1px -1px 3px 0px rgba(50, 50, 51, 1);
  --WEBSITE-button: 5px 5px 7px 0px rgba(159, 184, 210, 0.28), -5px -5px 7px 0px rgba(255, 255, 255, 1);
  --DROP-SHADOW: 3px 3px 3px 0px rgba(174, 174, 192, 0.4), -3px -3px 3px 0px rgba(255, 255, 255, 1);
  --inner-shadow: 1px 1px 1px 0px rgba(67, 108, 230, 1), -1px -1px 1px 0px rgba(255, 255, 255, .14);
  --mat-menu-item-label-text-weight: 500;
  --mdc-switch-handle-width: 12px;
  --mdc-switch-handle-height: 12px;
  --mdc-switch-handle-shape: 50%;
}