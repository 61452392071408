@font-face {
  font-family: 'icomoon';
  src:  url('../assets/fonts/icomoon.eot?nemwj2');
  src:  url('../assets/fonts/icomoon.eot?nemwj2#iefix') format('embedded-opentype'),
  url('../assets/fonts/icomoon.ttf?nemwj2') format('truetype'),
  url('../assets/fonts/icomoon.woff?nemwj2') format('woff'),
  url('../assets/fonts/icomoon.svg?nemwj2#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  cursor: pointer;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  span {
    padding: 0 !important;
  }
}

.icon-file:before {
  content: "\e95d";
}

.icon-column_selector:before {
  content: "\e95b";
}
.icon-key:before {
  content: "\e959";
}
.icon-plan:before {
  content: "\e95a";
}
.icon-copy:before {
  content: "\e957";
}
.icon-area-line:before {
  content: "\e954";
}
.icon-area-poly:before {
  content: "\e955";
}
.icon-area-point:before {
  content: "\e956";
}
.icon-search-close:before {
  content: "\e953";
}
.icon-warning:before {
  content: "\e94f";
}
.icon-chat:before {
  content: "\e950";
}
.icon-unshared:before {
  content: "\e94c";
}
.icon-shared:before {
  content: "\e94d";
}
.icon-archived:before {
  content: "\e94e";
}

.icon-double_check:before {
  content: "\e949";
}

.icon-notifications:before {
  content: "\e94b";
}

.icon-project:before {
  content: "\e94a";
}
.icon-threshold:before {
  content: "\e948";
}

.icon-area-size:before {
  content: "\e949";
}

.icon-share:before {
  content: "\e947";
}

.icon-download:before {
  content: "\e946";
}
.icon-add-folder:before {
  content: "\e945";
}

.icon-filter:before {
  content: "\e944";
}
.icon-folder:before {
  content: "\e943";
}

.icon-Account:before {
  content: "\e900";
  
}
.icon-Account-1:before {
  content: "\e901";
  
}
.icon-Analytics:before {
  content: "\e902";
  
}
.icon-area:before {
  content: "\e903";
  
}
.icon-Areas:before {
  content: "\e904";
  
}
.icon-Areas-1:before {
  content: "\e905";
  
}
.icon-Arrow:before {
  content: "\e906";
  
}
.icon-Arrows:before {
  content: "\e907";
  
}
.icon-Calendar:before {
  content: "\e908";
  
}
.icon-Canceled:before {
  content: "\e909";
  
}
.icon-Chart:before {
  content: "\e90a";
  
}
.icon-check:before {
  content: "\e90b";
  
}
.icon-Circle:before {
  content: "\e90c";
  
}
.icon-Clear:before {
  content: "\e90d";
  
}
.icon-close:before {
  content: "\e90e";
  
}
.icon-collapse:before {
  content: "\e90f";
  
}
.icon-Dashboard:before {
  content: "\e910";
  
}
.icon-Double-Arrow:before {
  content: "\e911";
  
}
.icon-Download:before {
  content: "\e912";
  
}
.icon-Drag:before {
  content: "\e913";
  
}
.icon-Edit:before {
  content: "\e914";
  
}
.icon-expand:before {
  content: "\e915";
  
}
.icon-Expired:before {
  content: "\e916";
  
}
.icon-eye-closed:before {
  content: "\e917";
  
}
.icon-eye:before {
  content: "\e918";
  
}
.icon-Filter-for-saved-areas:before {
  content: "\e919";
  
}
.icon-Fire-risk:before {
  content: "\e91a";
  
}
.icon-focus-in:before {
  content: "\e91b";
  
}
.icon-Group:before {
  content: "\e91c";
  
}
.icon-Hamburger-menu:before {
  content: "\e91d";
  
}
.icon-Hashtag:before {
  content: "\e91e";
  
}
.icon-Help-center:before {
  content: "\e91f";
  
}
.icon-info:before {
  content: "\e920";
  
}
.icon-Layers:before {
  content: "\e921";
  
}
.icon-Line:before, .icon-line:before {
  content: "\e922";
  
}
.icon-Link:before {
  content: "\e923";
  
}
.icon-Location:before {
  content: "\e924";
  
}
.icon-log-out:before {
  content: "\e925";
  
}
.icon-Map:before {
  content: "\e926";
  
}
.icon-Menu:before {
  content: "\e927";
  
}
.icon-middle-close:before {
  content: "\e928";
  
}
.icon-minus:before {
  content: "\e929";
  
}
.icon-Mouse:before {
  content: "\e92a";
  
}
.icon-Pagination-Arrow:before {
  content: "\e92b";
  
}
.icon-Pagination-Arrow-1:before {
  content: "\e92c";
  
}
.icon-Password:before {
  content: "\e92d";
  
}
.icon-Plus:before {
  content: "\e92e";
  
}
.icon-Point .path1:before, .icon-point .path1:before {
  content: "\e92f";
  color: rgb(127, 143, 152);
}
.icon-Point .path2:before, .icon-point .path2:before {
  content: "\e930";
  margin-left: -0.6875em;
}
.icon-Point .path3:before, .icon-point .path3:before {
  content: "\e931";
  margin-left: -0.6875em;
  color: rgb(247, 249, 249);
}
.icon-polygon:before {
  content: "\e932";
  
}
.icon-Processed:before {
  content: "\e933";
  
}
.icon-Processing:before {
  content: "\e934";
  
}
.icon-remove-icon:before {
  content: "\e935";
  
}
.icon-Remove:before {
  content: "\e936";
  
}
.icon-Save:before {
  content: "\e937";
  
}
.icon-search-icon:before {
  content: "\e938";
  
}
.icon-Settings:before {
  content: "\e939";
  
}
.icon-small-close:before {
  content: "\e93a";
  
}
.icon-Square:before, .icon-rectangle:before {
  content: "\e93b";
  
}
.icon-Table:before {
  content: "\e93c";
  
}
.icon-Undo:before {
  content: "\e93d";
  
}
.icon-Upload-file:before {
  content: "\e93e";
  
}
.icon-Uploaded:before {
  content: "\e93f";
  
}
.icon-zoom-in:before {
  content: "\e940";
  
}
.icon-zoom-out:before {
  content: "\e941";
}

.icon-tags:before {
  content: "\e942";
}

.icon-more-horiz:before {
  content: "\e91d";
  transform: rotate(90deg);
  margin-left: 1px;
}
