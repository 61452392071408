.frmg-primary-button {
  padding: 16px 20px !important;
  font-size: 14px;
  line-height: 17px !important;
  font-weight: 600;
  border-radius: 4px !important;

  &:disabled {
    background: var(--primary-disabled) !important;
    color: var(--primary-disabled-text) !important;
  }

  &:not(:disabled) {
    background: var(--primary-gradient) !important;
    box-shadow: var(--button-shadow) !important;
    color: white !important;
  }

  &:hover {
    background: var(--primary-gradient-hover) !important;
  }

  .mdc-button__label {
    color: inherit;
    display: flex;
    align-items: center;
    letter-spacing: normal;
  }
}

.frmg-secondary-button {
  padding: 16px 20px !important;
  font-size: 14px;
  line-height: 17px !important;
  font-weight: 600;
  border-radius: 4px !important;

  &:not(:disabled) {
    background: var(--light-bg) !important;
    box-shadow: var(--button-shadow) !important;
    color: var(--main-text) !important;
  }

  &:hover {
    background: var(--light-blue) !important;
  }

  .mdc-button__label {
    color: inherit;
    display: flex;
    align-items: center;
    letter-spacing: normal;
  }
}

@keyframes highlighted {
  0% {
    box-shadow: 0 0 10.4px 0 rgba(184, 212, 229, 1);
  }

  100% {
    box-shadow: 0 0 10.4px 0 rgba(184, 212, 229, 0);
  }
}

.horizontal-separator {
  width: 100%;
  height: 3px;
  box-shadow: var(--separator-shadow);
  border-radius: 2px;
}