/* You can add global styles to this file, and also import other style files */
// try to set typograph
@use "@angular/material" as mat;

@include mat.core();
$my-primary: mat.define-palette(mat.$blue-grey-palette, 500);
$my-accent: mat.define-palette(mat.$green-palette, 600, 700, 800);
$my-typography: mat.define-typography-config(
        $font-family: 'Montserrat'
);

$my-theme: mat.define-light-theme((
        color: (
                primary: $my-primary,
                accent: $my-accent,
        ),
        typography: $my-typography,
));

@include mat.all-component-themes($my-theme);

// angular material theme styles
@import "~@angular/material/prebuilt-themes/deeppurple-amber.css";

//general variables
@import 'variables.scss';

// resets, variables, mixins, utilty
@import 'base.scss';

// container layout and grid systems
@import 'layout.scss';

// shared components including customization of shared (nested) angular components with custom styles
@import 'components.scss';


// font awesome
@import '~@fortawesome/fontawesome-free/css/all.css';

//icomoon font
@import "icomoon.scss";

html, body, .mat-typography {
  font-family: Montserrat, sans-serif;
}










