/* Montserrat Thin */
@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat/Montserrat-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

/* Montserrat Thin Italic */
@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat/Montserrat-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

/* Montserrat ExtraLight */
@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat/Montserrat-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

/* Montserrat ExtraLight Italic */
@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat/Montserrat-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

/* Montserrat Light */
@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat/Montserrat-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

/* Montserrat Light Italic */
@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat/Montserrat-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

/* Montserrat Regular */
@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat/Montserrat-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

/* Montserrat Italic */
@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat/Montserrat-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

/* Montserrat Medium */
@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat/Montserrat-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

/* Montserrat Medium Italic */
@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat/Montserrat-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

/* Montserrat SemiBold */
@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat/Montserrat-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

/* Montserrat SemiBold Italic */
@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat/Montserrat-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

/* Montserrat Bold */
@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat/Montserrat-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

/* Montserrat Bold Italic */
@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat/Montserrat-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

/* Montserrat ExtraBold */
@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat/Montserrat-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

/* Montserrat ExtraBold Italic */
@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat/Montserrat-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

/* Montserrat Black */
@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat/Montserrat-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

// icon styling
$hover-blue: #5796D7;
$black-hover-blue-filter: invert(56%) sepia(72%) saturate(359%) hue-rotate(170deg) brightness(87%) contrast(94%);

$active-blue: #85BBF3;
$black-active-blue-filter: invert(84%) sepia(13%) saturate(6699%) hue-rotate(181deg) brightness(96%) contrast(98%);

$button-active-lightblue: #E9F3F4;
$black-active-lightblue-filter: invert(98%) sepia(2%) saturate(1299%) hue-rotate(163deg) brightness(101%) contrast(92%);

$icon-white: #FFFFFF;
$icon-white-alpha85: rgba(255, 255, 255, 0.85);

$greyHover: #7F8F98;

$darkGreyText: #272727;

$lightBackground: #F7F9F9;

$grey: #A5B5BD;

$lightBlue: #85BBF3;

$menu-box-shadow: 1.5px 1.5px 3px 0px rgba(174, 174, 192, 0.40), -1px -1px 3px 0px #FFF;

// mixins

// menu-item common
@mixin menu-item-common {
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 4px;

}

@mixin menu-item-normal {
  @include menu-item-common;
  color: $grey;
}

@mixin menu-item-hover {
  @include menu-item-common;
  box-shadow: $menu-box-shadow;
}

@mixin menu-item-active {
  @include menu-item-common;
  color: $lightBlue;
  border-left: 3px solid $lightBlue;
}

@mixin menu-material-icons {
  //font-variation-settings: 'FILL' 0, 'wght' 100, 'GRAD' 0, 'opsz' 12;
  font-size: 18px;
  color: rgba(100, 100, 100, 0.9);
  font-weight: 100;
}

@mixin map-toolbar-material-icons {
  //font-variation-settings: 'FILL' 0, 'wght' 100, 'GRAD' 0, 'opsz' 12;
  font-size: 15px;
  color: #A5B5BD;

  //font-weight: 700;
}

@mixin menubar-icon-button-small {
  padding: 6px;
  border: 1px solid rgba(200, 200, 200, 0.5);
  width: 18px;
  height: 18px;
  border-radius: 4px;
  cursor: pointer;
}

@mixin menubar-icon-button {
  text-align: center;
  padding: 12px; //
  border: 1px solid rgba(200, 200, 200, 0.5);
  width: 18px;
  height: 18px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 700;
  color: #A5B5BD;

}

@mixin frmg-input-element {
  .mat-mdc-form-field {
    font-size: 12px !important;
    height: 32px !important;
    //margin-top: 10px !important;

    .mat-mdc-form-field-flex {
      margin-top: -12px !important;
    }

    .mat-mdc-form-field-subscript-wrapper {
      display: none !important;
    }

    .mdc-line-ripple::before {
      display: none !important;
    }

    .mdc-line-ripple::after {
      border-bottom-style: none !important;
    }

    .mdc-line-ripple--active {

    }

    .mdc-text-field--focused {

    }


  }
}

@mixin frmg-search-input-element {
  .mat-mdc-form-field {
    font-size: 12px !important;
    height: 32px !important;
    width: 200px;
    //margin-top: 10px !important;

    .mat-mdc-form-field-flex {
      margin-top: -12px !important;

      .mat-mdc-form-field-icon-suffix {
        .mat-icon {
          font-size: 18px !important;
          width: 18px;
          height: 18px;
        }

      }
    }

    .mat-mdc-form-field-subscript-wrapper {
      display: none !important;
    }

    .mdc-line-ripple::before {
      display: none !important;
    }

    .mdc-line-ripple::after {
      border-bottom-style: none !important;
    }

    .mdc-line-ripple--active {

    }

    .mdc-text-field--focused {

    }


  }
}


// menu styling


html, body {
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
}

body {
  margin: 0px;
  //font-family: Roboto, "Helvetica Neue", sans-serif;
  font-family: 'Montserrat', "Helvetica Neue", sans-serif;
}

input, h1, h2, h3, h4, p {
  font-family: 'Montserrat', sans-serif;
}

// placed here, this will impact all material tooltips.  Scope if necessary for other tooltip styles
.mat-mdc-tooltip .mdc-tooltip__surface {
  color: black !important;
  background-color: $icon-white-alpha85 !important;
  border: 1px solid rgba(200, 200, 200, 0.5);
  border-radius: 4px !important;
  margin-left: 10px;
}

// svg based buttons with default, active and hover stying


.interact-blue:hover {
  filter: $black-hover-blue-filter;
}

.control-button-icon-div {
  display: flex;
  padding-left: 0rem;
  padding-right: 0rem;
  //padding: .3125rem;
}


frmg-menu-item-active {
  .material-icons, .material-symbols-outlined {
    font-size: 18px;
  }

  .mat-accordion {
    width: 44px;

    .mat-expanded {
      mat-panel-title {
        .icon-div.interactive {
          color: forestgreen;
        }
      }

    }

    .mat-expansion-indicator::after {
      display: none;
    }


    .mat-expansion-panel-header {

      padding-left: 5px;
      padding-right: 0px;
      padding-top: 0px;

      .icon-div.interactive {
        padding: 6px;
        border: 1px solid rgba(200, 200, 200, 0.5);
        width: 18px;
        height: 18px;
        border-radius: 4px;
      }


      .icon-div.interactive:active {
        box-shadow: 1px 1px 0px 0px lightgray inset;
      }
    }


    .mat-expansion-panel-content {

      .mat-expansion-panel-body {
        padding-left: 6px;
        padding-right: 6px;
        padding-top: 12px;
        padding-bottom: 12px;

        .icon-div.interactive {
          padding: 6px;
          border: 1px solid rgba(200, 200, 200, 0.5);
          width: 18px;
          height: 18px;
          margin-bottom: 12px;
          border-radius: 4px;
          cursor: pointer;
        }
      }

    }
  }
}

frmg-layer-manager {
  .layer-container {
    //gap: 0.5rem; // custom spacing between expander headers
    .mat-mdc-tab.mdc-tab {
      padding: 0px;
    }

    .mat-expansion-panel {

      margin-left: 0.5rem;
      margin-right: 0.5rem;
      margin-bottom: 0.5rem;
      margin-top: 0.5rem;

      .mat-expansion-panel-body {
        //padding: 0.5rem;
        padding: 0rem;
      }

      .mat-expansion-panel-header {
        height: 32px;
        font-weight: 300;
      }
    }

    // the row of thumb plus buttons
    .layer-item-thumb {
      .layer-item-fab-button {
        width: 24px;
        height: 24px;
        margin: 5px;
      }

      .layer-item-fab-button i {
        font-size: 16px;
      }

    }
  }
}

frmg-open-close-right {
  @include frmg-search-input-element;
}

frmg-area-list-panel {
  // this holds the tagger
  .item-right {
    .layer-item-fab-button {
      width: 24px;
      height: 24px;
      margin: 5px;
    }

    .layer-item-fab-button i {
      font-size: 16px;
    }

    .mat-mdc-form-field {
      font-size: 14px;
      width: 90px !important;
    }

    .mat-mdc-form-field-infix {
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important;
      min-height: 32px;
    }

    .mat-mdc-form-field-subscript-wrapper {
      height: 0px;
    }

    .mat-mdc-option {
      font-size: 10px !important;
      min-height: 28px;
    }


  }

  @include frmg-search-input-element;
  // search input field
  /* .mat-mdc-form-field {
      font-size: 12px !important;
      height: 32px !important;
      width: 200px;
      //margin-top: 10px !important;

      .mat-mdc-form-field-flex {
          margin-top: -12px !important;
          .mat-mdc-form-field-icon-suffix {
              .mat-icon {
                  font-size: 18px !important;
                  width: 18px;
                  height: 18px;
              }

          }
      }

      .mat-mdc-form-field-subscript-wrapper {
          display: none !important;
      }

      .mdc-line-ripple::before {
          display: none !important;
      }
      .mdc-line-ripple::after {
          border-bottom-style: none !important;
      }
      .mdc-line-ripple--active {

      }

      .mdc-text-field--focused {

      }



  } */
}

frmg-area-list-item {

  .material-symbols-outlined {
    /* font-variation-settings:
        'FILL' 0,
        'wght' 400,
        'GRAD' 0,
        'opsz' 12; */
    font-size: 15px;
    color: #A5B5BD;;
  }

  .mat-mdc-icon-button.mat-mdc-button-base {
    --mdc-icon-button-state-layer-size: 24px;
    padding: 0px;
  }

  .tagger-chips {
    display: flex;
    flex-direction: column;
    //max-width: 90px;
    max-height: 48px;
    overflow-x: hidden;
    overflow-y: auto;

    .mat-mdc-chip {
      //margin-top: 2px;
      margin-bottom: 2px;
      --mdc-chip-container-height: 16px;
      --mdc-chip-label-text-size: 10px;

      .mat-mdc-standard-chip {
        --mdc-chip-container-height: 16px;
        padding-left: 4px !important;
      }

      .mat-mdc-standard-chip {
        --mdc-chip-label-text-size: 12px;
      }

      .mat-mdc-chip-remove {

        .mat-icon {
          padding-left: 2px;
          padding-right: 4px;
          height: 14px;
          width: 14px;
          font-size: 14px;
        }


      }
    }
  }

}

.files-list {

  .material-symbols-outlined {
    font-variation-settings: 'FILL' 0,
    'wght' 100,
    'GRAD' 0,
    'opsz' 24
  }
}

frmg-settings-menu {
  .material-icons, .material-symbols-outlined {
    font-size: 18px;
  }


}

frmg-teams-page {
  .mat-mdc-form-field {
    font-size: 12px !important;
    height: 32px !important;
    //margin-top: 10px !important;

    .mat-mdc-form-field-flex {
      margin-top: -12px !important;
    }

    .mat-mdc-form-field-subscript-wrapper {
      display: none !important;
    }

    .mdc-line-ripple::before {
      display: none !important;
    }

    .mdc-line-ripple::after {
      border-bottom-style: none !important;
    }

    .mdc-line-ripple--active {

    }

    .mdc-text-field--focused {

    }


  }

  .invite-button {
    .mat-mdc-raised-button.mat-mdc-button-base {
      height: 32px !important;
    }
  }
}

frmg-invite-member-dialog {
  @include frmg-input-element;

  .mat-mdc-form-field {
    width: 100%;
  }
}

frmg-team-table {
  .mat-column-role {
    min-width: 150px;
    max-width: 150px;
    width: 150px;
  }
}

.layer-item-opacity {
  margin-top: -12px;
  height: 36px;

  .mat-primary .mdc-slider__thumb {
    //height: 12px;
    //width: 12px;
    // border-radius: 50% !important;
    /* box-shadow: 0 2px 2px rgba(0, 0, 0, 0.14),
    0 3px 1px rgba(0, 0, 0, 0.12),
    0 1px 5px rgba(0, 0, 0, 0.2); */
  }

  .mdc-slider {
    //height: 12px;
  }

  .mat-mdc-slider {
    //width: 90%;
    //width: calc( 100% - 1rem );
    width: 90%;
    margin-left: 0px;

    .mdc-slider__thumb-knob {
      height: 1rem;
      width: 1rem;
      border-radius: 0.25rem;
      border-width: 1px;
    }
  }
}

/*  this controls the width of the dialogs */
.cdk-overlay-container {
  .cdk-overlay-pane {
    .mat-mdc-dialog-surface {
      //min-width: 50vw !important;
      background-color: #f7f9f9 !important;
      max-height: 90vh;

      &:has(.close-full) {
        overflow: visible;
      }
    }
  }
}

/* // tagger input and chip styling
.tagger-container {
    max-width: 220px;
    .tagger-selector {
        max-width: 125px;
        margin-right: 4px;


        .mat-mdc-form-field {
            font-size: 14px;
        }

        .mat-mdc-form-field-infix {
            padding-top: 0.5rem !important;
            padding-bottom: 0.5rem !important;
            min-height: 32px;
        }

        .mat-mdc-form-field-subscript-wrapper {
            height: 0px;
        }

        .mat-mdc-option {
            font-size: 10px !important;
            min-height: 28px;
        }
    }

    .tagger-chips {
        max-width: 90px;
        max-height: 60px;
        overflow-y: auto;
        .mat-mdc-chip {
            //margin-top: 2px;
            margin-bottom: 2px;
            --mdc-chip-container-height: 18px;
            --mdc-chip-label-text-size: 12px;

            .mat-mdc-standard-chip {
                --mdc-chip-container-height: 18px;
            }

            .mat-mdc-standard-chip {
                --mdc-chip-label-text-size: 10px;
            }
        }
    }
} */

//material style override
::ng-deep {
  .mat-mdc-text-field-wrapper {
    box-shadow: var(--inset-box-shadow);

    .mdc-notched-outline {
      display: none;
    }
  }

  .mat-mdc-chip {
    background: transparent !important;
    box-shadow: var(--main-box-shadow);
  }

  .cdk-overlay-pane .mat-mdc-snack-bar-container {
    position: absolute;
    background: none;
    box-shadow: none;
    margin: 0;
    padding: 0;
    border: none;
    border-radius: inherit;
    max-width: inherit;
    min-width: inherit;
    pointer-events: none;
    display: flex;

    .mdc-snackbar__surface {
      padding: 0;
      border: none;
      background: none;
      box-shadow: none;
    }

    .mdc-snackbar__label {
      padding: 0;
    }
  }

  .mdc-checkbox__background {
    padding: 3px;
    border-radius: 4px;
  }

  .mdc-checkbox__checkmark {
    top: 3px;
    right: 3px;
    bottom: 3px;
    left: 3px;
    width: auto;
  }

  .mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
  .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background,
  .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled ~ .mdc-checkbox__background {
    box-shadow: 2px 2px 3px 0 rgba(48, 94, 235, 1) inset, -1px -1px 2px 0 rgba(114, 148, 249, 1) inset;
  }

  .mdc-checkbox .mdc-checkbox__background {
    box-shadow: 1.5px 1.5px 3px 0 rgba(174, 174, 192, 0.4), -1px -1px 3px 0 rgba(255, 255, 255, 1);

  }

  .mdc-checkbox:active .mdc-checkbox__native-control ~ .mdc-checkbox__ripple,
  .mdc-checkbox:hover .mdc-checkbox__ripple,
  .mdc-checkbox .mdc-checkbox__native-control:focus ~ .mdc-checkbox__ripple {
    border-radius: 0 !important;
    background-color: var(--light-blue) !important;
    opacity: 1 !important;
  }

  .mat-mdc-menu-panel {
    max-width: unset !important;
  }

  .mdc-switch__icons {
    display: none;
  }

  .mat-mdc-slide-toggle .mdc-switch.mdc-switch--unselected .mdc-switch__handle::after {
    background: var(--white) !important;
    box-shadow: var(--shadow-on-white);
  }

  .mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected .mdc-switch__handle::after {
    background: var(--white) !important;
    box-shadow: var(--shadow-on-blue);
  }

  .mat-mdc-slide-toggle .mdc-switch {
    .mdc-switch__ripple, .mdc-switch__icons, .mdc-switch__shadow {
      display: none;
    }
  }

  .mdc-switch {
    width: 30px !important;
    height: 18px;
    border-radius: 9px;
    box-shadow: var(--inset-box-shadow);
    background: var(--light-bg) !important;

    .mdc-switch__handle {
      left: 3px;
    }

    &--selected {
      background: var(--blue) !important;
      box-shadow: var(--inset-box-shadow-blue);

      .mdc-switch__handle {
        left: -3px;
      }
    }
  }

  .mat-mdc-slide-toggle .mdc-switch .mdc-switch__track::before, .mat-mdc-slide-toggle .mdc-switch .mdc-switch__track::after {
    background: transparent !important;
  }

  .mat-mdc-menu-panel.mat-mdc-menu-panel {
    box-shadow: var(--standard-shadow);
  }

  //.mdc-switch__handle-track, .mdc-switch__track {
  //  transform: translateY(-1px) !important;
  //}

  .mat-mdc-select-placeholder, mat-select-trigger {
    font-size: 14px;
    font-weight: 500;
    font-family: Montserrat, sans-serif;
    line-height: 17px;
    color: var(--grey);
  }

  mat-select-trigger {
    color: var(--text);
  }

  .mat-mdc-select-placeholder {
    color: var(--grey) !important;
  }
}


.search-panel {
  min-width: 90px;
  background: var(--light-bg);
  padding: 0 !important;
  border-radius: 4px !important;
  box-shadow: var(--standard-shadow) !important;

  .mdc-list-item--selected, .mat-mdc-option:hover {
    background: var(--light-blue) !important;

    .mdc-list-item__primary-text {
      color: var(--active-icon) !important;
    }
  }


  .mat-mdc-option {
    padding: 0;
    min-height: 32px;

    span {
      padding: 8px;
    }
  }

  .mat-pseudo-checkbox {
    display: none;
  }

  .mdc-list-item__primary-text {
    font-family: Montserrat, sans-serif !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 15px !important;
    color: var(--text) !important;
  }
}

.role-panel {
  background: var(--light-bg);
  padding: 0 !important;
  border-radius: 4px !important;
  box-shadow: var(--standard-shadow) !important;

  .mdc-list-item--selected, .mat-mdc-option:hover {
    background: var(--light-blue) !important;

    .mdc-list-item__primary-text {
      color: var(--active-icon) !important;
    }
  }


  .mat-mdc-option {
    padding: 8px;
    min-height: unset;
    box-sizing: border-box;
    min-height: 36px;
  }

  .mat-pseudo-checkbox {
    display: none;
  }

  .mdc-list-item__primary-text {
    font-family: Montserrat, sans-serif !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 15px !important;
    color: var(--text) !important;
    text-transform: capitalize;
  }
}


::ng-deep .mat-mdc-option.mdc-list-item {
  flex-direction: row-reverse;
}

.download-menu-panel > div {
  display: flex;
  flex-direction: column;
  padding: 6px 0;

  span {
    cursor: pointer;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    padding: 8px;
    min-width: auto !important;


    &:hover {
      background-color: var(--light-blue);
    }
  }
}


.cdk-drag-preview.list-column {
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: var(--text);
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  min-width: 250px;
  cursor: pointer;
  padding: 12px 0;

  mat-slide-toggle {
    padding-right: 2px;
  }

  .cdkDrag {
    flex-grow: 1;
  }

  .icon-Drag {
    color: var(--grey);
    font-size: 14px;
    line-height: 14px;
  }
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner:after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  margin-top: -20px;
  margin-left: -20px;
  border-radius: 50%;
  border: 5px solid var(--light-blue);
  border-top-color: var(--blue);
  animation: spinner 1s linear infinite;
}

input:-webkit-autofill,
input:-webkit-autofill::first-line {
  background-color: transparent !important;
  font-family: Montserrat, sans-serif !important;
}

.mat-mdc-form-field-icon-suffix, .mat-mdc-form-field-icon-prefix {
  color: var(--grey);
  font-size: 15px;
}

.mat-mdc-form-field-icon-prefix {
  gap: 8px;
}

.mat-mdc-form-field-has-icon-suffix, .mat-mdc-form-field-has-icon-prefix {
  .mat-mdc-text-field-wrapper {
    padding: 0 16px !important;
  }
}

.mat-focused {
  .mat-mdc-text-field-wrapper {
    outline: 2px solid var(--blue);
    background: var(--light-blue);
  }
}

.mat-form-field-invalid {
  .mat-mdc-text-field-wrapper {
    outline: 2px solid var(--error);
    border-radius: 4px !important;
  }
}


.mat-mdc-text-field-wrapper {
  box-shadow: var(--inset-box-shadow);
  border-radius: 4px !important;;

  .mdc-notched-outline {
    display: none;
  }
}

.mat-mdc-form-field-subscript-wrapper {
  display: none;
}

.mdc-text-field__input, .mdc-text-field__input::placeholder {
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}

.mdc-text-field__input::placeholder {
  color: var(--grey) !important;
}

.mdc-text-field__input {
  color: var(--text);
}

.mdc-text-field--disabled {
  background: rgb(234, 239, 241);
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 6px;
  padding-bottom: 6px;
  min-height: 36px;
}


.address-search-options {
  background-color: var(--light-bg) !important;
  margin-left: -4px;
  margin-top: 2px;
  padding: 0 !important;

  .horizontal-separator {
    width: calc(100% - 8px);
    position: absolute;
    left: 0;
    top: 0;
  }

  .mat-mdc-option {
    padding: 12px 10px;
    font-family: Montserrat, sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: var(--text);
  }

  .mdc-list-item--selected, .mat-mdc-option:hover {
    background-color: var(--light-blue) !important;

    .mdc-list-item__primary-text {
      color: var(--active-icon) !important;
    }
  }
}

p {
  margin: 0;
}

.folder-tree mat-option {
  visibility: hidden;
  position: absolute;
  height: 0 !important;
  width: 0 !important;
  display: block;

}

.folder-tree mat-tree-node {
  cursor: pointer;
}

.folder-tree .selected {
  background-color: var(--light-blue);
}


.folder-tree {
  padding: 0 !important;

  mat-tree {
    flex-direction: column;
    display: flex;
    padding: 16px 0 16px 8px;
    gap: 12px;
  }

  .mat-tree-node {
    min-height: 18px;
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    text-align: left;
    color: var(--text);

    button {
      margin-right: 4px;
    }

    .mat-mdc-icon-button.mat-mdc-button-base {
      padding: 0;
      width: 12px;
      height: 12px;


      mat-icon {
        width: 12px;
        font-size: 12px;
        color: var(--grey);
      }

      .mat-mdc-button-touch-target {
        height: 12px;
        width: 12px;
      }
    }

    .icon-project {
      color: var(--blue);
      font-size: 12px;
      line-height: 12px;
      margin-right: 2px;
    }
  }
}

::ng-deep .exposure-color-tooltip .mdc-tooltip__surface {
  position: relative;
  font-weight: 600 !important;
  display: flex;
  flex-direction: row-reverse;
  gap: 4px;


  &::after {
    content: "Exposure: " !important;
    font-weight: 500 !important;
    font-family: Montserrat, sans-serif;
    font-size: 12px;
  }

}

.frmg-measure {
  box-shadow: var(--shadow-on-map);
  background-color: white;
  padding: 10px 12px;
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  color: var(--text);
}


.menu-slot {
  min-height: 36px;

  &:hover {
    background-color: var(--light-blue) !important;
  }

  ::ng-deep & > span {
    font-weight: 500;
    font-size: 12px !important;
  }
}


.custom-tooltip {
  display: flex;
  flex-direction: column;
  padding: 12px;
  background: var(--white);
  border-radius: 4px;
  gap: 14px;

  .tooltip-item {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .tooltip-item span {
    font-size: 12px;
    font-weight: 500;
    font-family: Montserrat, sans-serif;
    line-height: 15px;

    &:last-child {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
    }
  }

  .tooltip-color {
    width: 12px;
    height: 12px;
    border-radius: 2px;
  }
}

.close-full {
  position: absolute;
  right: -42px;
  top: 10px;
  border-radius: 4px;
  background: transparent;
  padding: 10px;
  cursor: pointer;
  color: var(--grey);
  font-size: 12px;
  background: var(--light-bg);

  &:hover {
    color: var(--grey-hover);
  }

  &:active {
    color: var(--active-icon);
  }
}

.analysis-map {
  max-height: 100%;
  max-width: 100%;
  border-radius: 4px;
}


.ol-layer {
  background-color: transparent !important;
}